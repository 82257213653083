<!--更多协议签署-->
<template>
  <div class="protocolsUpload">
    <div v-for="(item, index) in protocolList" :key="index" class="item">
      <div class="left">{{ item.name }}</div>
      <div class="right" @click="onSign(item)">
        {{ item.code === '3004005' ? $t('推送客户') :  item.url ? $t('已签署') : $t('发起签署') }}
        <van-icon name="arrow" />
      </div>
    </div>
    <van-popup v-model="isShow" closeable close-icon="close" :overlay="false" position="bottom" class="protocols-sign-popup">
      <div>{{ $t('请选择') }}{{ selectProcotol.name }}{{ $t('签署方式') }}</div>
      <div class="sign-type">
        <div class="btn-text" @click="signSelect('offline')">
          <p>{{$t('线下签署')}}</p>
          <van-icon name="records-o" color="#FF7D00" size="56" />
        </div>
        <div class="btn-text" @click="signSelect('online')">
          <p>{{$t('线上签署')}}</p>
          <van-icon name="chat-o" color="#00C800" size="56" />
        </div>
      </div>
      <div><van-button round type="primary"  class="btn  btn-item" @click="isShow=false">{{ $t('取消') }}</van-button></div>
    </van-popup>
  </div>
</template>
<script>
import loading from '@/utils/loading'
import orderServices from '@/services/orderServices'
import { mapGetters } from 'vuex'
import deliveryServices from '@/services/deliveryServices.js'

export default {
  data() {
    return {
      orderDetail: {},
      isShow: false,
      selectProcotol: {}
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'dictHash']),
    protocolList() {
      const { templateFile,pdiStatus } = this.orderDetail
      const displayProtocol = ['3004001', '3004002', '3004003']
      if (pdiStatus === '4004003'){
        displayProtocol.push('3004005')
      }
      let list = (this.dictHash && this.dictHash[3004] || []).filter(({ code }) => displayProtocol .includes(code)).map((item) => { return {
        code: item.code,
        name: item.name,
        url: ''
      } })
      const sortList = ['3004001', '3004003', '3004002','3004005']
      list = list.sort((a,b) => {
        return sortList.indexOf(a.code) - sortList.indexOf(b.code)
      })
      
      if (templateFile && templateFile.length > 0) {
        return list.map(item => {
          return {
            code: item.code,
            name: item.name,
            url: templateFile.find(({ code }) => code === item.code)?.templateUrl
          }
        })
      }
      return list
    }
  },
  mounted() {
    this.getOrderDetail()
  },
  methods: {
    async getOrderDetail() {
      loading.showLoading()
      const res = await orderServices.getOrderDetail({ id: this.$route.query.id })
      loading.hideLoading()
      this.orderDetail = res
    },
    onSign(item){
      const { code,url } = item
      if (url&&code!=='3004005'){
        // 已签署，回显
        this.goUpload(item)
        return
      }
      if (['3004001','3004003'].includes(code)){
        // 选择线上or线下
        this.isShow = true
        this.selectProcotol = item
      } else if (code==='3004002'){
        // 直接线下
        this.goUpload(item)
      } else if (code==='3004005'){
        // 跳转提车催促函的h5
        this.$router.push({
          path: '/pdf-preview',
          query: {
            id: this.$route.query.id,
            procotolCode: code,
            isPreview: true
          }
        })
      }
    },
    signSelect(type){
      if (type==='online'){
        // 线上签署
        this.onlineSign()
      } else if (type==='offline'){
        // 线下签署
        this.goUpload(this.selectProcotol)
      }
    },
    onlineSign(){
      const { code,name } = this.selectProcotol
      const vm = this
      const { id } = this.$route.query
      this.isShow = false
      deliveryServices.getProtocolLink(code,id).then(res=>{
        if (res?.linkUrl){
          const { linkUrl } = res
          // 调用微信JS-SDK提供的分享接口
          wx.invoke(
            'shareAppMessage', {
              title: name, // 分享标题
              desc: '', // 分享描述
              link: linkUrl, // 分享链接
              imgUrl: '', // 分享封面
              enableIdTrans: 1, // 是否开启id转译，不填默认为0
            }, function (res) {
              if (res.err_msg == 'shareAppMessage:ok') {
              }
            }
          )
        }
      })
      
    },
    // 去上传
    goUpload(item) {
      localStorage.setItem('templateFileInfo', JSON.stringify(item))
      this.$router.push({
        path: '/commonUpload',
        query: {
          id: this.$route.query.id,
        }
      })
    },
  }
}
</script>
<style lang="less" scoped>
.protocolsUpload{
  padding: 16px;
  display: flex;
  flex-direction: column;
  .item{
    background: #fff;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    box-shadow: 0px 2px 5px #21212138;
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
    .right{
      display: flex;
      align-items: center
    }
  }
}

.protocols-sign-popup {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 2px 5px #21212138;
  .sign-type{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .btn-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      p {
        font-size: 16px;
        margin-bottom: 10px;
        color: rgba(13, 23, 26, 0.45);
      }
    }
  }
  .btn-item{
    width: 100%;
    margin-top: 20px;
  }
}
</style>